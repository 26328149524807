import "./FooterStyles.css";
import React from 'react';
import { 
    FaHome, 
    FaPhone, 
    FaMailBulk, 
    FaFacebook, 
    FaInstagram, 
    FaTwitter 
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            <div className="left">
                <div className="location">
                    <FaHome size={20} style={{color: "#fff", marginRight: "2rem"}}/>
                    <div>
                        <p>Avenida Paulista, 1200 - 10º Andar - conj. 1002</p>
                        <p>SP, São Paulo</p>
                    </div>
                </div>
                <div className="phone">
                    <FaPhone size={20} style={{color: "#fff", marginRight: "2rem"}}/>
                    <div>
                        <p>(11) 0202-0303</p>
                    </div>
                </div>
                <div className="email">
                    <FaMailBulk size={20} style={{color: "#fff", marginRight: "2rem"}}/>
                    <div>
                        <p>info@anyticket.com.br</p>
                    </div>
                </div>                
            </div>
            <div className="right">
                <h4>Sobre AnyTicket</h4>
                <p>Somos uma empresa focada em levar diversão e entretenimento para nossos clientes.
                    Oferecemos desde tickets para "loucos" por futebol quanto para os amantes por
                    shows dos mais diversos generos musicais.
                    Venham conferir!!
                </p>
                <div className="social">
                    <FaFacebook size={30} style={{color: "#fff", marginRight:"1rem"}} />
                    <FaInstagram size={30} style={{color: "#fff", marginRight:"1rem"}} />
                    <FaTwitter size={30} style={{color: "#fff", marginRight:"1rem"}} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
/*
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
Este componente sera utilizado como um DataFile,
obtendo as informacoes necessarias para criar os Cards de Eventos
E os dados serao enviados para o AllCards.js
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/
const api_update_personalize = "https://api2.gustech.tec.br/ticketApp/api/update_personalize";

/*Funcao handleClick*/
   
function handleClick(e, eventId, eventType) {

    e.preventDefault();
    var data = {
        "itemId":eventId.toString(),
        "categoriaEvento":eventType
    }

    console.log(JSON.stringify(data))

    fetch(api_update_personalize, {  
        method: 'POST',  
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
        }    
    })

    .then(response => response.json())
    .then(json => console.log(json))
    .catch(error => console.log(error));

}

export default handleClick

import React from 'react'
import Navbar from '../components/Navbar'; /* Importando componente NavBar.js */
import Footer from '../components/Footer';
import ShowHeroImg from '../components/HeroShowImg';
import GetCardShows from '../components/GetCardsDataShows';


const Shows = () => {
  return (
    <div>
        <Navbar />
        <ShowHeroImg />
        <GetCardShows />
        <Footer />
    </div>
  )
}

export default Shows
import "./HeroimgStyles.css"
import React from 'react'
import HeroImg from "../images/hero-img.jpg"

const Heroimg = () => {
  return (
    <div className="hero">
        <div className="mask">
            {/*<img className="hero-img" src={HeroImg} alt="HeroImg" />*/}
            <img className="hero-img" src="https://media.gustech.tec.br/images/hero-img.jpg" alt="HeroImg" />        </div>
        <div className="content">
            {/*<h1> Buy Any Ticket and Have Fun!!</h1>*/}
            {/*Comentar o texto acima do código*/}
        </div>
    </div>
  )
}

export default Heroimg
/*
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
Este componente sera utilizado como um DataFile,
obtendo as informacoes necessarias para criar os Cards de Eventos
E os dados serao enviados para o AllCards.js
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/
import "./CardStyles.css";
import React, { Component } from "react";
import BuildCards from '../components/BuildCards';

const api_lista_sports = "https://api2.gustech.tec.br/ticketApp/api/lista_esportes";

/*Criando classe*/
class GetCardSports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lista_sports: []
        };
    }
    
    componentDidMount() {
        fetch(api_lista_sports, {  
            method: 'GET',  
            mode: 'cors',     
          })
        .then(response => response.json())
        .then(result => {
            console.log(result)
            this.setState({ lista_sports: result})
        });
    }
    
    render = () => <BuildCards data={this.state.lista_sports}/>

}

export default GetCardSports
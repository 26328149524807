import React from 'react';
import Navbar from '../components/Navbar'; /* Importando componente NavBar.js */
import Heroimg from '../components/Heroimg';
import Footer from '../components/Footer';
import GetCardAllEvents from '../components/GetCardsData';
import GetRecommendations from '../components/GetRecommendation';

const Home = () => {
  return (
    <div>
        {/* Chamando component Navbar na pagina Home */}
        <Navbar /> 
        <Heroimg />
        {/*<GetRecommendations />*/}
        <GetRecommendations />
        <GetCardAllEvents />
        <Footer />
    </div>
  )
}

export default Home
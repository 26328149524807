/*
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
Este componente ira importar os Cards renderizados no AllCards.js
e irá mostrar no Browser.
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/

import "./RecommendationStyles.css";
import React from 'react';
import handleClick from "./ClickHandler";
import { NavLink } from "react-router-dom";


const BuildRecommendationCards = (props) => {
  return (
    <div className="recommendation-header">
        <h1 className="recommendation-heading">Recommendations</h1>
        <div className="recommendation-container">
            {props.data.map((eventos, index) => (
                <div className="recommendation-card" key={ index }>
                    <img src={eventos.image_path} alt="BuildRecommendationCards"/>
                    <h2 className="recommendation-name">{eventos.name}</h2>
                    <div className="recommendation-description">
                        <p className="recommendation-datetime"> Data do Evento: {eventos.date} às {eventos.time}</p>
                        <h4 className="recommendation-address"> Local: {eventos.address}</h4>
                        <div className="recommendation-button">
                            <NavLink to="" className="button" onClick={(event) => handleClick(event, eventos.id, eventos.type)}>Buy</NavLink>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
};

export default BuildRecommendationCards
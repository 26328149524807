/*
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
Este componente sera utilizado como um DataFile,
obtendo as informacoes necessarias para criar os Cards de Eventos
E os dados serao enviados para o AllCards.js
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/
import "./RecommendationStyles.css";
import React, { Component } from "react";
import BuildRecommendationCards from '../components/BuildRecommendationCards';

const api_get_recommendation = "https://api2.gustech.tec.br/ticketApp/api/get_recommendations";

/*Criando classe*/
class GetRecommendations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lista_eventos: []
        };
    }
    
    componentDidMount() {
        fetch(api_get_recommendation, {  
            method: 'GET',  
            mode: 'cors',    
          })
        .then(response => response.json())
        .then(result => {
            console.log(result)
            this.setState({ lista_eventos: result})
        });
    }
    
    render = () => <BuildRecommendationCards data={this.state.lista_eventos}/>

}

export default GetRecommendations
import React from "react";
import "./index.css";
import Home from "./routes/Home";
import Sports from "./routes/Sports";
import Shows from "./routes/Shows";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shows" element={<Shows />} />
        <Route path="/sports" element={<Sports />} />
      </Routes>
    </>
  );
}

export default App;

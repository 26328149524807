import React from 'react'
import Navbar from '../components/Navbar'; /* Importando componente NavBar.js */
import Footer from '../components/Footer';
import SportHeroImg from '../components/HeroSportImg';
import GetCardSports from '../components/GetCardsDataSports';



const Sports = () => {
  return (
    <div>
        <Navbar /> 
        <SportHeroImg />
        <GetCardSports />
        <Footer />
    </div>
  )
}

export default Sports
import "./NavbarStyles.css";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);


    {/* 
    Block de codigo abaixo foi criado para que o background da NavBar seja sempre Black
    quando for feito scroll down da pagina
    */}

    const [color, setColor] = useState(false);
    const changeColor = () => {
        if(window.scrollY >=100){
            setColor(true);
        }else{
            setColor(false);
        }
    };

    {/*Faz Listener de algum evento no browser para trigar o função changeColor()*/}
    window.addEventListener("scroll", changeColor);

    return (
    /*
    Na linha de código abaixo <div>, tem um if(?), onde se color = True, então 
    className="header header-bg" (2x valores), senão
    className="header"
    Desta forma esta className recebera o style definido no NavbarStyles.css
    */
    <div className={color ? "header header-bg": "header"}>
        <Link to="/">
            <h1>AnyTicket</h1>
        </Link>
        <ul className="nav-menu">
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <Link to="/Sports">Sports</Link>
            </li>
            <li>
                <Link to="/Shows">Shows</Link>
            </li>                        
        </ul>
        {/*++++++++++++++++++++++++++++++++++++++++++++++++
        No final verificar se é possível remover o hamburger menu e do CSS também pq é só para Celular
        ++++++++++++++++++++++++++++++++++++++++++++++++++*/}

        <div className="hamburger">
            <FaBars size={20} style={{color: "#fff"}}/>
        </div>

    </div>
  )
}

export default Navbar
import "./HeroimgStyles.css"
import React from 'react'

const HeroShowImg = () => {
  return (
    <div className="hero">
        <div className="mask">
            <img className="hero-img" src="https://media.gustech.tec.br/images/hero-img.jpg" alt="HeroImg" />
        </div>
    </div>
  )
}

export default HeroShowImg